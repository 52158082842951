<template>
  <section>
      <div class="content-header">
        <h2>Academic resilience in the COVID context </h2>
      </div>
      <div class="content-wrapper">
        <p>We all respond to stress and adversity—like what we’ve collectively faced during the COVID-19 pandemic—differently.</p>
        <p>Have a read through this Q&A from the Harvard T. H. Chan School of Public Health, in which Dr. Kristen Nishimi and Dr. Karmel Choi apply <a href="https://www.hsph.harvard.edu/health-happiness/applying-the-science-of-resilience-to-the-covid-19-crisis-qa-with-dr-karmel-choi-and-dr-kristen-nishimi/" target="_blank">the science of resilience to the COVID crisis</a>. </p>

        <h3>Academic resilience in a post-COVID world</h3>
        <p>In this article for post-secondary educators, the authors discuss how mental health screening and marker recognition need to become essential tools to measure academic effectiveness in a post COVID-19 world: <a href="https://www.edcan.ca/articles/academic-resilience-in-a-post-covid-world/" target="_blank">Academic Resilience in a Post-COVID World: A multi-level approach to capacity building</a> by Dr. Louis Volante, Dr. Don A. Klinger and Dr. Joe M Barrett.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
